import HomeHeader from '../../components/Layout/Header/HomeHeader'
import { Box, Button, Typography,Card,CardContent } from '@material-ui/core';
import useStyles from './styles';
const Pricing = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
    <Box className={classes.videoContainer}>
      <Box pb={4}>
        <HomeHeader />	
      </Box>
      <Box m={1} pb={2}>
      <Box className={classes.mainTitle} > PRICING </Box>
        <Box className={classes.cardContainer} >
          <Card className={classes.card} >
            <CardContent>
              <Typography gutterBottom>
               <strong> Basic Course </strong>
              </Typography>
              <Typography variant="h5" component="div">
                INR 350/- 
              </Typography>
            </CardContent>        
          </Card>
          <Card className={classes.card} >
            <CardContent>
              <Typography gutterBottom>
                <strong> Advance Course </strong>
              </Typography>
              <Typography variant="h5" component="div">
                INR 1000/- 
              </Typography>
            </CardContent>        
          </Card>
        </Box>
      </Box>
    </Box>
  </Box>
  )
}

export default Pricing