import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountCircle from '@material-ui/icons/AccountCircle';

import useStyles from './style';
import Logo from '../../Logo';
import { disableInstrunctorDetailFlag } from '../../../store/reducers/instaructorDetail';

const Header = () => {
	const classes = useStyles();
	let navigate = useNavigate();
	const dispatch = useDispatch();

	const studentDetail = JSON.parse(localStorage.getItem('studentDetail') || '');

	const instructorDetailFlag = useSelector(
		(state: any) => state.instructorDetail.instructorDetailFlag
	);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleProfileMenuOpen = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = (event: any) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const handleLogout = () => {
		dispatch(disableInstrunctorDetailFlag());
		localStorage.clear();
		navigate('/');
	};

	const menuId = 'primary-search-account-menu';
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<MenuItem onClick={() => navigate('/payment-history')}>
				<NavLink to='/payment-history' style={{ textDecoration: 'none' }}>
					{({ isActive }) => (
						<Typography
							variant='body1'
							noWrap
							className={`${classes.headerItem} ${
								isActive ? classes.active : ''
							}`}
						>
							Payment history
						</Typography>
					)}
				</NavLink>
			</MenuItem>

			<MenuItem onClick={() => navigate('/contact-us')}>
				<NavLink to='/contact-us' style={{ textDecoration: 'none' }}>
					{({ isActive }) => (
						<Typography
							variant='body1'
							noWrap
							className={`${classes.headerItem} ${
								isActive ? classes.active : ''
							}`}
						>
							Contact Us
						</Typography>
					)}
				</NavLink>
			</MenuItem>

			<MenuItem onClick={handleLogout}>Logout</MenuItem>
		</Menu>
	);

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem onClick={() => navigate('/dashboard')}>
				<NavLink to='/dashboard' style={{ textDecoration: 'none' }}>
					{({ isActive }) => (
						<Typography
							variant='body1'
							noWrap
							className={`${classes.headerItem} ${
								isActive ? classes.active : ''
							}`}
						>
							Dashboard
						</Typography>
					)}
				</NavLink>
			</MenuItem>
			<MenuItem onClick={() => navigate('/activity')}>
				<NavLink to='/activity' style={{ textDecoration: 'none' }}>
					{({ isActive }) => (
						<Typography
							variant='body1'
							noWrap
							className={`${classes.headerItem} ${
								isActive ? classes.active : ''
							}`}
						>
							Activity
						</Typography>
					)}
				</NavLink>
			</MenuItem>
			{false && (
				<MenuItem onClick={() => navigate('/instructor-detail')}>
					<NavLink to='/instructor-detail' style={{ textDecoration: 'none' }}>
						{({ isActive }) => (
							<Typography
								variant='body1'
								noWrap
								className={`${classes.headerItem} ${
									isActive ? classes.active : ''
								}`}
							>
								Know your Instructor
							</Typography>
						)}
					</NavLink>
				</MenuItem>
			)}

			<MenuItem onClick={() => navigate('/payment-history')}>
				<NavLink to='/payment-history' style={{ textDecoration: 'none' }}>
					{({ isActive }) => (
						<Typography
							variant='body1'
							noWrap
							className={`${classes.headerItem} ${
								isActive ? classes.active : ''
							}`}
						>
							Payment history
						</Typography>
					)}
				</NavLink>
			</MenuItem>

			<MenuItem onClick={() => navigate('/contact-us')}>
				<NavLink to='/contact-us' style={{ textDecoration: 'none' }}>
					{({ isActive }) => (
						<Typography
							variant='body1'
							noWrap
							className={`${classes.headerItem} ${
								isActive ? classes.active : ''
							}`}
						>
							Contact Us
						</Typography>
					)}
				</NavLink>
			</MenuItem>
			<MenuItem onClick={handleLogout}>
				<Typography variant='body1' noWrap className={`${classes.headerItem} `}>
					Logout
				</Typography>
			</MenuItem>
		</Menu>
	);
	const capitalizeFirstLetter = (str: string) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};
	return (
		<div className={classes.grow}>
			<AppBar position='static' className={classes.appBar}>
				<Toolbar>
					<Logo />

					<div className={classes.grow} />
					<div className={classes.sectionDesktop}>
						<Typography className={classes.welcomeMsg}>
							{`Hello ${capitalizeFirstLetter(
								studentDetail?.student_name
							)}, Welcome to Anubhuti`}
						</Typography>
						<NavLink to='/dashboard' style={{ textDecoration: 'none' }}>
							{({ isActive }) => (
								<Typography
									variant='body1'
									noWrap
									className={`${classes.headerItem} ${
										isActive ? classes.active : ''
									}`}
								>
									Dashboard
								</Typography>
							)}
						</NavLink>
						<NavLink to='/activity' style={{ textDecoration: 'none' }}>
							{({ isActive }) => (
								<Typography
									variant='body1'
									noWrap
									className={`${classes.headerItem} ${
										isActive ? classes.active : ''
									}`}
								>
									Activity
								</Typography>
							)}
						</NavLink>
						{false && (
							<NavLink
								to='/instructor-detail'
								style={{ textDecoration: 'none' }}
							>
								{({ isActive }) => (
									<Typography
										variant='body1'
										noWrap
										className={`${classes.headerItem} ${
											isActive ? classes.active : ''
										}`}
									>
										Know your Instructor
									</Typography>
								)}
							</NavLink>
						)}
						<IconButton
							edge='end'
							aria-label='account of current user'
							aria-controls={menuId}
							aria-haspopup='true'
							onClick={handleProfileMenuOpen}
							color='inherit'
						>
							{studentDetail?.profilePhotoUrl ? (
								<img
									src={studentDetail.profilePhotoUrl}
									style={{
										width: 35,
										height: 35,
										borderRadius: '50%',
										objectFit: 'cover',
									}}
									alt='profile'
								/>
							) : (
								<AccountCircle style={{ color: '#000' }} />
							)}

							<ExpandMoreIcon style={{ color: '#000' }} />
						</IconButton>
					</div>
					<div className={classes.sectionMobile}>
						<Typography className={classes.welcomeMsg}>
						{`Hello ${capitalizeFirstLetter(
								studentDetail?.student_name
							)}, Welcome to Anubhuti`}
						</Typography>
						<IconButton
							aria-label='show more'
							aria-controls={mobileMenuId}
							aria-haspopup='true'
							onClick={handleMobileMenuOpen}
							color='inherit'
						>
							{studentDetail?.profilePhotoUrl ? (
								<img
									src={studentDetail.profilePhotoUrl}
									style={{
										width: 35,
										height: 35,
										borderRadius: '50%',
										objectFit: 'cover',
									}}
									alt='profile'
								/>
							) : (
								<AccountCircle style={{ color: '#000' }} />
							)}
							<ExpandMoreIcon style={{ color: '#000' }} />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
			{renderMenu}
		</div>
	);
};

export default Header;
