import { Box, Typography } from '@material-ui/core';
import useStyles from './style';
const NotFound = () => {
	const classes = useStyles();
	return (
		<Box className={classes.notFound}>
			<Typography className={classes.notFoundText}> Not Found </Typography>
		</Box>
	);
};

export default NotFound;
