import { FC, ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import Home from './contant/Home';
import Register from './contant/Register';

import './App.css';
import Login from './contant/Login';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './contant/Dashboard';
import Activity from './contant/Activity';
import ContactUs from './contant/ContactUs';
import InstructorDetail from './contant/InstructorDetail';
import { enableInstrunctorDetailFlag } from './store/reducers/instaructorDetail';
import PaymentHistory from './contant/PaymentHistory';
import NotFound from './components/NotFound';

import PrivacyPolicy from './contant/Home/PrivacyPolicy';
import TermsAndCondition from './contant/Home/TermsAndCondition';
import Refund from './contant/Home/Refund';
import Pricing from './contant/Home/Pricing';
import Contactus from './contant/Home/Contactus';

const App: FC = (): ReactElement => {
	const dispatch = useDispatch();
	const advancedCourseStatus = localStorage.getItem('advancedCourseStatus');
	useEffect(() => {
		if (advancedCourseStatus === 'true') {
			dispatch(enableInstrunctorDetailFlag());
		}
	}, []);

	return (
		<Routes>
			<Route path='/' element={<Home />} />
			<Route path='/register' element={<Register />} />
			<Route path='/login' element={<Login />} />
			
			<Route path='/privacy-policy' element={<PrivacyPolicy />} />
			<Route path='/term-conditions' element={<TermsAndCondition />} />
			<Route path='/refund' element={<Refund />} />
			<Route path='/pricing' element={<Pricing />} />
			<Route path='/contact-us' element={<Contactus />} />
			<Route
				path='/dashboard'
				element={
					<PrivateRoute>
						<Dashboard />
					</PrivateRoute>
				}
			/>
			<Route
				path='/activity'
				element={
					<PrivateRoute>
						<Activity />
					</PrivateRoute>
				}
			/>
			<Route
				path='/instructor-detail'
				element={
					<PrivateRoute>
						<InstructorDetail />
					</PrivateRoute>
				}
			/>
			<Route
				path='/payment-history'
				element={
					<PrivateRoute>
						<PaymentHistory />
					</PrivateRoute>
				}
			/>
			<Route
				path='/contact-us'
				element={
					<PrivateRoute>
						<ContactUs />
					</PrivateRoute>
				}
			/>
			<Route path='*' element={<NotFound />} />
		</Routes>
	);
};

export default App;
