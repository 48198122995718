import { Box } from '@material-ui/core';
import ChildrenYoga from '../../assets/images/yoga.png';
import useStyles from './styles';
const Animation = () => {
	const classes = useStyles();
	return (
		<Box
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				background:
					'linear-gradient(180deg, #28B8F4 0%, #078EC7 84.79%, #056994 100%)',
			}}
		>
			<Box className={classes.yoga}>
				<Box className={classes.baba}>
					<img src={ChildrenYoga} alt='yoga' />
				</Box>
			</Box>
		</Box>
	);
};

export default Animation;
