import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		height: '100vh',
		[theme.breakpoints.down(1180)]: {
			borderTop: '15px solid #28B8F4',
		},
	},
	yoga: {
		height: '200px',
	},
	baba: {
		position: 'relative',
		animation: '$yogababa 2s linear alternate	infinite',
		textAlign: 'center',

		'& img': {
			width: 250,
			height: 275,
		},
	},
	'@keyframes yogababa': {
		'0%': {
			bottom: '5%',
		},
		'100%': {
			bottom: '30%',
		},
	},
	footer: {},

	courseText: {
		fontSize: 24,
		color: '#525356',
		fontWeight: 600,
		[theme.breakpoints.down(400)]: {
			fontSize: 18,
		},
	},
	arrowIcon: {
		borderRadius: '50%',
		backgroundColor: 'white',
		boxShadow: '0px 4px 4px rgb(0 0 0 / 3%)',
		cursor: 'pointer',
		padding: 4,
	},
	videoContainer: {
		width: '100%',
		padding: '24px 30px',
		boxSizing: 'border-box',
		position: 'relative',
		marginBottom: 100,
		[theme.breakpoints.down(1180)]: {
			width: '100%',
		},
	},
	video: {
		height: '40vh',
		width: '100%',
		[theme.breakpoints.down(600)]: {
			height: '30vh',
		},
	},
	pdf: {
		height: '50vh',
		width: '100%',
		[theme.breakpoints.down(600)]: {
			height: '50vh',
		},
	},
	animation: {
		width: '40%',
		[theme.breakpoints.down(1180)]: {
			display: 'none',
		},
	},
	loginButton: {
		border: '2px solid #EF7F1A',
		color: '#AD5301',
		fontSize: 18,
		padding: '10px 25px',
		fontWeight: 'bold',
		borderRadius: 7,
		textTransform: 'none',
		lineHeight: '28px',
		width: '100%',
		[theme.breakpoints.down(410)]: {
			fontSize: 14,
			padding: '10px 8px',
		},
	},
	registerButton: {
		color: '#FFF',
		fontSize: 18,
		borderRadius: 7,
		padding: '10px 50px',
		fontWeight: 'bold',
		backgroundColor: '#EF7F1A',
		textTransform: 'none',
		lineHeight: '28px',
		width: '100%',
		[theme.breakpoints.down(410)]: {
			fontSize: 14,
			padding: '10px 8px',
		},
		'&:hover': {
			backgroundColor: '#EF7F1A',
		},
	},
	footerbuttons: {
		position: 'fixed',
		bottom: 0,
		backgroundColor: '#FFF',
		width: '100%',
	},
	buttons: {
		display: 'flex',
		justifyContent: 'center',
		paddingTop: '10px',
		padding: 20,
		gap: 15,
		[theme.breakpoints.down(768)]: {
			flexDirection: 'column',
		},
	},
	downloadIcon: {
		textAlign: 'right',
		cursor: 'pointer',
	},
	openPDFText: {
		fontWeight: 'bold',
		margin: '10px 10px 10px',
		textAlign: 'end',
		cursor: 'pointer',
	},
	pdfIframe: {
		minHeight: 250,
	},
	title:{
		fontSize:16,
		fontWeight: 'bold',
		textDecoration:'underline',
		margin:'15px 0px'
	},
	subTitle:{
		fontSize:15,
		fontWeight: 'bold',
		margin:'10px 0px'
	},
	text:{
		fontSize:14,
		margin:'8px 0px'
	},
	capitalText:{
		fontSize:16,
		margin:'15px 0px'
	},
	mainTitle:{
		textAlign: 'center',
		fontSize:20,
		fontWeight: 'bold',
		marginBottom:28 
	},
	cardContainer:{
		display:'flex',
		justifyContent: 'center',
		gap:20,
		flexWrap:'wrap'
	},
	card:{
		padding:'10px 25px',
		cursor:'pointer'
	}
}));

export default useStyles;
