import moment from 'moment';

export const columns = (handleToRedirectContactUs: any) => [
	{
		Header: 'Date',
		accessor: (data: any) => moment.unix(data.date).format('MM/DD/YYYY'),
		headerStyle: {
			backgroundColor: '#000',
			color: '#FFF',
			fontWeight: 600,
			textAlign: 'center',
		},
		dataStyle: {
			textAlign: 'center',
		},
	},
	{
		Header: 'Description',
		accessor: (data: any) => data.description,
		headerStyle: {
			backgroundColor: '#000',
			color: '#FFF',
			fontWeight: 600,
			textAlign: 'center',
		},
		dataStyle: {
			maxWidth: 100,
			wordBreak: 'break-word',
			textAlign: 'center',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			// display: '-webkit-box',
			lineClamp: 2,
			WebkitLineClamp: 2,
			WebkitBoxOrient: 'vertical',
		},
	},
	// {
	// 	Header: 'Validity',
	// 	accessor: 'validity',
	// 	headerStyle: {
	// 		backgroundColor: '#000',
	// 		color: '#FFF',
	// 		fontWeight: 600,
	// 		textAlign: 'center',
	// 	},
	// 	dataStyle: {
	// 		textAlign: 'center',
	// 	},
	// },
	{
		Header: 'Payment method',
		accessor: 'paymentMethod',
		headerStyle: {
			backgroundColor: '#000',
			color: '#FFF',
			fontWeight: 600,
			textAlign: 'center',
		},
		dataStyle: {
			textAlign: 'center',
		},
	},
	{
		Header: 'Amount',
		accessor: (d: any) => {
			let num: string = (d.amount / 100).toString();
			return `INR ${parseFloat(num).toFixed(2)}`;
		},
		headerStyle: {
			backgroundColor: '#000',
			color: '#FFF',
			fontWeight: 600,
			textAlign: 'center',
		},
		dataStyle: {
			textAlign: 'center',
		},
	},
	{
		Header: ' ',
		accessor: () => (
			<div onClick={handleToRedirectContactUs}> Report a Problem </div>
		),
		headerStyle: {
			backgroundColor: '#000',
			color: '#FFF',
			fontWeight: 600,
			textAlign: 'center',
		},
		dataStyle: {
			textAlign: 'center',
			fontWeight: 600,
			color: '#57595E',
			cursor: 'pointer',
		},
	},
];
