import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
	name: 'instructorDetail',
	initialState: {
		instructorDetailFlag: false,
	},
	reducers: {
		enableInstrunctorDetailFlag: (state) => {
			state.instructorDetailFlag = true;
		},
		disableInstrunctorDetailFlag: (state) => {
			state.instructorDetailFlag = false;
		},
		toggleInstrunctorDetailFlag: (state, action) => {
			state.instructorDetailFlag += action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	enableInstrunctorDetailFlag,
	disableInstrunctorDetailFlag,
	toggleInstrunctorDetailFlag,
} = counterSlice.actions;

export default counterSlice.reducer;
