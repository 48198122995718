import * as yup from 'yup';

export const loginSchema = yup.object({
	phone: yup
		.string()
		.required('phone numner is required')
		.test(
			'10 digit',
			'Phone number must be 10 digit',
			(value: any) => value.length === 10
		),
});

export const mobileSchema = yup.object({
	phone: yup
		.string()
		.required('phone numner is required')
		.test(
			'10 digit',
			'Phone number must be 10 digit',
			(value: any) => value.length === 10
		),
});

export const registerSchema = yup.object({
	name: yup.string().required('name  is required'),
	profilePhotoUrl: yup.string(),
	email: yup.string().email(),
	birthdate: yup.string().required('birthdate is required'),
	phone: yup
		.string()
		.required('phone numner is required')
		.test(
			'10 digit',
			'Phone number must be 10 digit',
			(value: any) => value.length === 10
		),
});

export const contactUsSchema = yup.object({
	name: yup.string().required('name  is required'),
	phone: yup
		.string()
		.required('phone numner is required')
		.test(
			'10 digit',
			'Phone number must be 10 digit',
			(value: any) => value.length === 10
		),
	msg: yup.string().required('message  is required'),
});
