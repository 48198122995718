import { FC, ReactElement, useRef, useState, useEffect } from 'react';
import {
	ActivityContainerType,
	VideoContainerType,
	PerchasedActivityType,
} from './type';
import { toast } from 'react-toastify';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import {
	Box,
	Typography,
	SwipeableDrawer,
	Button,
	TextField,
} from '@material-ui/core';
import DateBox from './DateBox';
import Carousel from 'react-multi-carousel';
import API from '../../api/Api';

import 'react-multi-carousel/lib/styles.css';
import useStyles from './style';
import { MONTHS_NAME, DAYS } from '../../constants';

const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
	},
	tablet: {
		breakpoint: { max: 1024, min: 600 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 600, min: 0 },
		items: 1,
	},
};

const VideoContainer: FC<VideoContainerType> = ({
	id,
	title,
	poster,
}): ReactElement => {
	const classes = useStyles();

	const [videoUrl, setVideoUrl] = useState('');
	const [flag, setFlag] = useState(false);

	const userID = localStorage.getItem('userId');
	const getVideo = async (videoId: string) => {
		try {
			const getVideoAccess: any = await API.get(
				`course/getSignedUrl/${userID}/${videoId}`
			);
			await API.post(`course/contentHistory/${userID}/${videoId}`);
			setVideoUrl(getVideoAccess?.url);
		} catch (err: any) {
			toast.error(err.response.data.error);
		}
	};
	useEffect(() => {
		if (videoUrl !== '') {
			setFlag(true);
		}
	}, [videoUrl]);

	return (
		<Box m={2}>
			{flag ? (
				<video
					controls
					className={classes.video}
					controlsList='nodownload'
					poster={poster}
					autoPlay
				>
					<source src={videoUrl} type='video/mp4' />
				</video>
			) : (
				<Box
					className={classes.video}
					style={{
						background: `linear-gradient(-45deg, #ebebebcc, #ebebebcc) fixed,url(${poster})`,
						backgroundSize: 'cover',
						position: 'relative',
					}}
				>
					<PlayCircleFilledWhiteIcon
						onClick={() => getVideo(id)}
						className={classes.playIcon}
					/>
				</Box>
			)}
			<Typography className={classes.title}>{title}</Typography>
		</Box>
	);
};

const ActivityContainer: FC<ActivityContainerType> = ({
	id,
	activityName,
	description,
	poster,
	toggleDrawer,
	activityId,
	setSelectedActivityVideo,
	setSelectedActivity,
}): ReactElement => {
	const classes = useStyles();

	const [videoUrl, setVideoUrl] = useState('');
	const [flag, setFlag] = useState(false);

	const userID = localStorage.getItem('userId');
	const getVideo = async (videoId: string) => {
		try {
			const getVideoAccess: any = await API.get(
				`course/getSignedUrl/${userID}/${videoId}`
			);
			await API.post(`course/contentHistory/${userID}/${videoId}`);
			setVideoUrl(getVideoAccess?.url);
		} catch (err: any) {
			toast.error(err.response.data.error);
		}
	};
	useEffect(() => {
		if (videoUrl !== '') {
			setFlag(true);
		}
	}, [videoUrl]);

	const handleSelectActivity = async (e: any) => {
		setSelectedActivityVideo({ id, activityName, description, poster });
		try {
			const activity: any = await API.get(
				`course/feedback/${userID}/${activityId}`
			);
			setSelectedActivity({
				id: activity._id,
				feedbackForms: activity.feedbackForms,
				overallFeedback: activity.overallFeedback,
			});
		} catch (err: any) {
			toast.error(err.response.data.error);
		}
		toggleDrawer(e);
	};

	return (
		<Box m={2} className={classes.activity}>
			<Box className={classes.activityHeader}>
				<Typography className={classes.activityTitle}>
					{activityName}
				</Typography>
				<Typography
					className={classes.activityFeedback}
					onClick={(e) => handleSelectActivity(e)}
				>
					Share your feedback
				</Typography>
			</Box>
			{flag ? (
				<video
					controls
					className={classes.video}
					autoPlay
					controlsList='nodownload'
				>
					<source src={videoUrl} type='video/mp4' />
				</video>
			) : (
				<Box
					className={classes.video}
					style={{
						background: `linear-gradient(-45deg, #ebebebcc, #ebebebcc),url(${poster})`,
						backgroundSize: 'cover',
						position: 'relative',
					}}
				>
					<PlayCircleFilledWhiteIcon
						onClick={() => getVideo(id)}
						className={classes.playIcon}
					/>
				</Box>
			)}
			<Typography className={classes.description}>{description}</Typography>
		</Box>
	);
};

const PerchasedActivity: FC<PerchasedActivityType> = (
	adavanceCourse
): ReactElement => {
	const classes = useStyles();
	const carouselRef = useRef<any>(null);

	const [state, setState] = useState(false);
	const userID = localStorage.getItem('userId');

	const [selectedActivityVideo, setSelectedActivityVideo] = useState<any>({});
	const [selectedActivity, setSelectedActivity] = useState<any>({});

	useEffect(() => {}, [selectedActivity]);

	const toggleDrawer = (event: any) => {
		if (
			event &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}
		setState((preState) => !preState);
	};
	const handleChangeActivity = ({ e, id }: any) => {
		const updateActivity: any = selectedActivity?.feedbackForms.map(
			(feedbackForm: any) =>
				id === feedbackForm._id
					? {
							...feedbackForm,
							[e.target.name]: e.target.value,
					  }
					: feedbackForm
		);
		setSelectedActivity((preState: any) => ({
			...preState,
			feedbackForms: updateActivity,
		}));
	};

	const handleChangeOverallFeedback = (e: any) => {
		setSelectedActivity((preState: any) => ({
			...preState,
			overallFeedback: e.target.value,
		}));
	};

	const handleSubmitActivity = async () => {
		try {
			const updateActivityFeedback: any = await API.put(
				`course/feedback/${userID}/${selectedActivity.id}`,
				{
					feedbackForms: selectedActivity.feedbackForms,
					overallFeedback: selectedActivity.overallFeedback,
				}
			);

			toast.success(updateActivityFeedback.message);
		} catch (err: any) {
			toast.error(err.response.data.error);
		}
	};

	return (
		<Box m={2}>
			{state && (
				<SwipeableDrawer
					anchor={'right'}
					open={state}
					onClose={toggleDrawer}
					onOpen={toggleDrawer}
				>
					<Box className={classes.drawerSpace}>
						<Box style={{ width: '100%', textAlign: 'center' }}>
							<Box className={classes.header}>
								<Typography className={classes.drawerTitle}>
									Feedback Form
								</Typography>
								<CloseIcon
									className={classes.closeIcon}
									onClick={toggleDrawer}
								/>
							</Box>
							<Box className={classes.mainContent}>
								<VideoContainer
									id={selectedActivityVideo?.id}
									title={selectedActivityVideo?.activityName}
									poster={selectedActivityVideo?.poster}
								/>
								{selectedActivity?.feedbackForms.map(
									({ feedback, status, date, _id }: any) => {
										const jsDate = new Date(date);
										const month = jsDate.getMonth();
										const day = jsDate.getDate();
										const weekName = jsDate.getDay();
										return (
											<DateBox
												monthName={MONTHS_NAME[month]}
												date={day}
												weakDayName={DAYS[weekName]}
												status={status}
												feedback={feedback}
												id={_id}
												handleChangeActivity={handleChangeActivity}
											/>
										);
									}
								)}
								<Box className={classes.observationSection}>
									<Typography className={classes.observationText}>
										Overall Self Observation after completion of activity
									</Typography>
									<TextField
										fullWidth
										id='standard-required'
										defaultValue=''
										variant='outlined'
										multiline
										rows={2}
										value={selectedActivity.overallFeedback}
										name='overallFeedback'
										onChange={handleChangeOverallFeedback}
										placeholder='Feedback of the day'
									/>
								</Box>
							</Box>
							<Box className={classes.footer}>
								<Button
									className={classes.submitFeedback}
									onClick={handleSubmitActivity}
								>
									Submit feedback
								</Button>
							</Box>
						</Box>
					</Box>
				</SwipeableDrawer>
			)}

			<Box
				m={2}
				display='flex'
				justifyContent='space-between'
				alignItems='center'
			>
				<Box>
					<Typography className={classes.courseText}>
						Your Activities
					</Typography>
				</Box>
				<Box display='flex' alignItems='center'>
					<Box mr={3}>
						<ChevronLeftIcon
							className={classes.arrowIcon}
							onClick={() => carouselRef.current.previous()}
						/>
					</Box>
					<Box mr={3}>
						<ChevronRightIcon
							className={classes.arrowIcon}
							onClick={() => carouselRef.current.next()}
						/>
					</Box>
				</Box>
			</Box>
			<Carousel ref={carouselRef} arrows={false} responsive={responsive}>
				{adavanceCourse.adavanceCourse.map(
					({ _id, activity_name, content, description }: any) => (
						<Box key={_id}>
							<ActivityContainer
								activityName={activity_name}
								description={description}
								toggleDrawer={toggleDrawer}
								poster={content?.thumbnailUrl}
								id={content?._id}
								activityId={_id}
								setSelectedActivityVideo={setSelectedActivityVideo}
								setSelectedActivity={setSelectedActivity}
							/>
						</Box>
					)
				)}
			</Carousel>
		</Box>
	);
};

export default PerchasedActivity;
