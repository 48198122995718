import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	desktopView: {
		[theme.breakpoints.down(600)]: {
			display: 'none',
		},
	},
	mobileView: {
		display: 'none',
		[theme.breakpoints.down(600)]: {
			display: 'block',
		},
	},
	odd: {
		backgroundColor: '#F2F4F8',
	},
	even: {
		backgroundColor: '#FFF',
	},
	card: {
		backgroundColor: '#FFF',
		margin: '10px 5px ',
		borderRadius: '10',
	},
	cardBadge: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 10,
		gap: 10,
	},
	title: {
		fontWeight: 600,
		fontSize: 18,
		margin: 10,
	},

	cardTitle: {
		color: '#9497A0',
	},
	longText: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		lineClamp: 2,
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
	},
	report: {
		color: '#57595E',
		fontWeight: 600,
		cursor: 'pointer',
	},
}));

export default useStyles;
