import { FC, ReactElement, useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import SuspenseLoader from '../../components/SuspenseLoader';
import moment from 'moment';
import { toast } from 'react-toastify';
import Hidden from '@material-ui/core/Hidden';
import Layout from '../../components/Layout';
import { VideoContainerType } from './type';

import {
	Box,
	Typography,
	Paper,
	Button,
	StepContent,
	StepLabel,
	Step,
	Stepper,
	ButtonGroup,
	CircularProgress,
} from '@material-ui/core';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import API from '../../api/Api';
import useStyles from './style';

const VideoContainer: FC<VideoContainerType> = ({
	id,
	title,
	date,
	poster,
}): ReactElement => {
	const classes = useStyles();

	const [videoUrl, setVideoUrl] = useState('');
	const [flag, setFlag] = useState(false);
	const videoRef = useRef<HTMLVideoElement>(null);
	const userID = localStorage.getItem('userId');
	let navigate = useNavigate();
	const getVideo = async (videoId: string) => {
		try {
			const getVideoAccess: any = await API.get(
				`course/getSignedUrl/${userID}/${videoId}`
			);
			setVideoUrl(getVideoAccess?.url);
		} catch (err: any) {
			toast.error(err.response.data.error);
		}
	};
	useEffect(() => {
		if (videoUrl !== '') {
			setFlag(true);
		}
	}, [videoUrl]);

	const onPlay = () => {
		getVideo(id);
	};

	return (
		<>
			<Box display='flex' alignItems='center'>
				<Box>
					{flag ? (
						<video
							controls
							className={classes.video}
							controlsList='nodownload'
							poster={poster}
							autoPlay
						>
							<source src={videoUrl} type='video/mp4' />
						</video>
					) : (
						<Box
							className={classes.video}
							style={{
								background: `linear-gradient(-45deg, #ebebebcc, #ebebebcc) fixed,url(${poster})`,
								backgroundSize: 'cover',
								position: 'relative',
							}}
						>
							<PlayCircleFilledWhiteIcon
								onClick={() => getVideo(id)}
								className={classes.playIcon}
							/>
						</Box>
					)}
				</Box>
				<Box className={classes.videoInfo}>
					<Typography className={classes.title}>{title}</Typography>
					<Typography className={classes.date}>{date}</Typography>
					<Box className={classes.buttons}>
						<Box className={classes.playButtons} onClick={onPlay}>
							<PlayCircleFilledIcon
								className={classes.playIcons}
								style={{ color: '#00A0E3' }}
							/>
							<span> Play again </span>
						</Box>
						<Box
							className={classes.reportButtons}
							onClick={() => navigate('/contact-us')}
						>
							<FiberManualRecordIcon
								className={classes.dotIcons}
								style={{ color: '#C4C4C4' }}
							/>
							<span> Report a problem </span>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};

const Avtivity = () => {
	const classess = useStyles();

	const [allActivity, setAllActivity] = useState<any>({});
	const [offset, setOffset] = useState(0);
	const userID = localStorage.getItem('userId');
	const [loader, setLoader] = useState<boolean>(true);
	useEffect(() => {
		const getActivity = async () => {
			setLoader(true);
			try {
				const activities: any = await API(
					`course/contentHistory/${userID}?offset=${offset}&limit=${10}`
				);
				setAllActivity(activities);
			} catch (err: any) {
				toast.error(err.response.data.error);
			}
			setLoader(false);
			setOffset((preState) => preState + 10);
		};
		getActivity();
	}, []);

	const handleNext = async () => {
		try {
			const activities: any = await API(
				`course/contentHistory/${userID}?offset=${offset}&limit=${10}`
			);
			setAllActivity((preActivity: any) => ({
				contentDetails: [
					...preActivity.contentDetails,
					...activities.contentDetails,
				],
				noOfContents: activities.noOfContents,
			}));
		} catch (err: any) {
			toast.error(err.response.data.error);
		}
		setOffset((preState) => preState + 10);
	};

	return (
		<Layout>
			{loader ? (
				<SuspenseLoader />
			) : (
				<Box className={classess.root}>
					<Hidden xsDown>
						<Stepper orientation='vertical' className={classess.stepper}>
							<InfiniteScroll
								dataLength={allActivity.contentDetails.length}
								next={handleNext}
								hasMore={
									allActivity.contentDetails.length < allActivity.noOfContents
								}
								loader={
									<CircularProgress size={64} disableShrink thickness={3} />
								}
							>
								{Object.keys(allActivity).length > 0 &&
								allActivity?.contentDetails.length > 0 ? (
									allActivity?.contentDetails.map(
										(activity: any, index: number) => (
											<Step active={true} key={index + 1}>
												<StepLabel
													icon={<FiberManualRecordOutlinedIcon />}
												></StepLabel>

												<StepContent>
													<VideoContainer
														id={activity._id}
														title={activity.content_name}
														date={moment(activity.createdAt).format('lll')}
														poster={activity.thumbnailUrl}
													/>
												</StepContent>
											</Step>
										)
									)
								) : (
									<Box className={classess.noActivity}>
										<Typography> No Activity </Typography>
									</Box>
								)}
							</InfiniteScroll>
						</Stepper>
					</Hidden>
					<Box className={classess.mobileView}>
						<InfiniteScroll
							dataLength={allActivity.contentDetails.length}
							next={handleNext}
							hasMore={
								allActivity.contentDetails.length < allActivity.noOfContents
							}
							loader={
								<CircularProgress size={64} disableShrink thickness={3} />
							}
						>
							{Object.keys(allActivity).length > 0 &&
							allActivity?.contentDetails.length > 0 ? (
								allActivity?.contentDetails.map(
									(activity: any, index: number) => (
										<Box key={index + 1} m={1}>
											<VideoContainer
												id={activity._id}
												title={activity.content_name}
												date={moment(activity.createdAt).format('lll')}
												poster={activity.thumbnailUrl}
											/>
										</Box>
									)
								)
							) : (
								<Box className={classess.noActivity}>
									<Typography> No Activity </Typography>
								</Box>
							)}
						</InfiniteScroll>
					</Box>
				</Box>
			)}
		</Layout>
	);
};

export default Avtivity;
