import { useState, useRef, ReactElement, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import {
	Box,
	Button,
	Grid,
	InputAdornment,
	Typography,
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import OtpInput from 'react-otp-input';
import { useForm } from 'react-hook-form';
import TextField from '../../components/Form/TextField';
import Logo from '../../components/Logo';
import Animation from '../Home/Animation';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { registerSchema, mobileSchema } from '../../schema/validationSchema';
import { displayRozarpay } from '../../utills/rozarpay';

import API from '../../api/Api';

import useStyles from './style';

const Regiater: FC = (): ReactElement => {
	const classes = useStyles();
	const [profile, setProfile] = useState('');
	const [otp, setOtp] = useState('');
	const inputFileRef = useRef<HTMLInputElement | null>(null);

	const [step, setStep] = useState<number>(1);

	const navigate = useNavigate();

	const {
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm({ resolver: yupResolver(registerSchema) });

	const {
		control: controlStep1,
		handleSubmit: handleSubmitStep1,
		formState: { errors: errorMobile },
	} = useForm({ resolver: yupResolver(mobileSchema) });

	const onNext = async (data: any) => {
		const { phone } = data;
		try {
			const sendOTP: any = await API.post(`student/register/${phone}/otp`);
			toast.success('OTP sent successfully');
			setValue('phone', data.phone);
			setStep(2);
		} catch (err: any) {
			toast.error(err.response.data.error);
		}
	};

	const setLocalStorageAuthData = (
		x_auth_token: string,
		role: string,
		userId: string,
		advancedCourseStatus: string,
		studentDetail: any
	) => {
		localStorage.setItem('token', x_auth_token);
		localStorage.setItem('role', role);
		localStorage.setItem('userId', userId);
		localStorage.setItem('advancedCourseStatus', advancedCourseStatus);
		localStorage.setItem('studentDetail', JSON.stringify(studentDetail));
	};

	const handleRozarpayResponse = (
		x_auth_token: string,
		role: string,
		userId: string,
		advancedCourseStatus: string,
		studentDetail: any
	) => {
		setLocalStorageAuthData(
			x_auth_token,
			role,
			userId,
			advancedCourseStatus,
			studentDetail
		);

		navigate('/dashboard');
	};

	const onSubmit = async (data: any) => {
		const { birthdate, name, email, phone } = data;

		if (otp && otp.length === 4) {
			try {
				const registerUser: any = await API.post('student/register', {
					user_phone: phone,
					student_name: name,
					email,
					profilePhotoUrl: profile,
					birthDate: birthdate,
					otp,
				});

				const { id, amount, currency } = registerUser.order;
				const { x_auth_token, role, userId, studentDetail } = registerUser;
				displayRozarpay({
					orderId: id,
					amount,
					currency,
					x_auth_token,
					role,
					userId,
					handleRozarpayResponse,
					studentDetail,
				});
				if (registerUser.success) {
					toast.success('Register user successfully');
					navigate('/dashboard');
				}
			} catch (err: any) {
				toast.error(err.response.data.error);
			}
		} else {
			toast.error('Please enter OTP');
		}
	};

	const handleResendOtp = async () => {
		// Register student api
		try {
			await API.post(`student/register/${watch('phone')}/otp`);
			toast.success('OTP resent successfully');
		} catch (err: any) {
			toast.error(err.response.data.error);
		}
	};

	const uploadProfile = async (e: any) => {
		const imageFile = e.target.files[0];
		const options = {
			maxSizeMB: 1,
		};
		try {
			const compressedFile = await imageCompression(imageFile, options);
			const formData = new FormData();
			formData.append('profilePhoto', compressedFile);
			const uploadImage: any = await API.post('student/upload', formData);
			setProfile(uploadImage.url);
			setValue('profilePhotoUrl', compressedFile?.name);
		} catch (e) {
			console.log('error', e);
		}
	};

	const onRedirectToLogin = () => {
		navigate('/login');
	};

	const onBack = () => {
		setStep(1);
	};

	return (
		<Box className={classes.container}>
			<Box className={classes.registerContainer}>
				<Box pb={4}>
					<Logo auth={true} />
				</Box>

				<Typography className={classes.welcomText}>
					Welcome Register For Basic Course
				</Typography>

				{step === 2 && (
					<Typography className={classes.backText} onClick={onBack}>
						<Box>
							<ArrowBackIcon />
						</Box>
						<Box>Back </Box>
					</Typography>
				)}

				{step === 1 && (
					<Box className={classes.form}>
						<form onSubmit={handleSubmitStep1(onNext)} autoComplete='off'>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										name='phone'
										type='number'
										defaultValue=''
										label='Phone number *'
										rules={{ required: 'Please enter a phone number' }}
										variant='outlined'
										control={controlStep1}
										error={!!errorMobile.phone}
										helperText={
											errorMobile.phone ? errorMobile?.phone?.message : ''
										}
										onWheel={(event: any) => event.currentTarget.blur()}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<Typography> +91</Typography>
												</InputAdornment>
											),
										}}
									/>
									<Typography
										onClick={onRedirectToLogin}
										className={classes.loginRedirect}
									>
										Login With Anubhuti
									</Typography>
								</Grid>

								<Button
									type='submit'
									variant='contained'
									className={classes.registerButton}
									
								>
									Next
								</Button>
							</Grid>
						</form>
					</Box>
				)}

				{step === 2 && (
					<Box className={classes.form}>
						<form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										name='name'
										type='text'
										defaultValue=''
										label='Full name *'
										rules={{ required: 'Please enter a full name' }}
										variant='outlined'
										control={control}
										error={!!errors.name}
										helperText={errors.name ? errors?.name?.message : ''}
									/>
								</Grid>
								<input
									type='file'
									id='profilePicture'
									name='profilePhotoUrl'
									ref={inputFileRef}
									style={{ display: 'none' }}
									accept='image/*'
									onChange={uploadProfile}
								/>
								<Grid item xs={12}>
									<TextField
										name='profilePhotoUrl'
										type='text'
										defaultValue=''
										label='Upload your profile photo'
										rules={{ required: 'Please enter a full name' }}
										variant='outlined'
										control={control}
										error={!!errors.profile}
										helperText={errors.profile ? errors?.profile?.message : ''}
										disabled
										InputProps={{
											endAdornment: (
												<InputAdornment
													className={classes.fileIcon}
													position='end'
													onClick={() => inputFileRef?.current?.click()}
												>
													<AttachFileIcon />
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										name='birthdate'
										type='date'
										defaultValue=''
										label='Birthdate *'
										InputLabelProps={{
											shrink: true,
										}}
										rules={{ required: 'Please enter a birthdate' }}
										variant='outlined'
										control={control}
										error={!!errors.birthdate}
										helperText={
											errors.birthdate ? errors?.birthdate?.message : ''
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										name='email'
										type='email'
										defaultValue=''
										label='Email address'
										rules={{ required: 'Please enter a email' }}
										variant='outlined'
										control={control}
										error={!!errors.email}
										helperText={errors.email ? errors?.email?.message : ''}
									/>
								</Grid>

								<Grid item xs={12}>
									<TextField
										name='phone'
										type='number'
										defaultValue=''
										label='Phone number *'
										rules={{ required: 'Please enter a phone number' }}
										variant='outlined'
										control={control}
										error={!!errors.phone}
										helperText={errors.phone ? errors?.phone?.message : ''}
										onWheel={(event: any) => event.currentTarget.blur()}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<Typography> +91</Typography>
												</InputAdornment>
											),
										}}
										disabled
									/>
								</Grid>

								<Grid item xs={12}>
									<Box
										display='flex'
										alignItems='center'
										justifyContent='space-between'
										py={1}
									>
										<Typography variant='body2' className={classes.enterOTP}>
											Enter OTP
										</Typography>
										<Typography
											variant='body2'
											className={classes.resendOTP}
											onClick={handleResendOtp}
										>
											Resend OTP
										</Typography>
									</Box>
									<OtpInput
										value={otp}
										onChange={(otp: any) => setOtp(otp)}
										isInputNum={true}
										placeholder='Enter Otp'
										separator={<span>-</span>}
										containerStyle={{
											width: '100%',
										}}
										inputStyle={{
											width: '100%',
											padding: '15px',
											fontSize: '1rem',
										}}
										hasErrored={true}
									/>
								</Grid>

								<Button
									type='submit'
									variant='contained'
									className={classes.registerButton}
								>
									Register me
								</Button>
							</Grid>
						</form>
					</Box>
				)}
			</Box>
			<Box className={classes.animationContainer}>
				<Animation />
			</Box>
		</Box>
	);
};

export default Regiater;
