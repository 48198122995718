import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	courseText: {
		fontSize: 24,
		color: '#525356',
		fontWeight: 600,
		[theme.breakpoints.down(400)]: {
			fontSize: 18,
		},
	},
	video: {
		height: '250px',
		width: '100%',
		[theme.breakpoints.down(600)]: {
			height: '30vh',
		},
	},
	playIcon: {
		position: 'absolute',
		fontSize: 40,
		top: '50%',
		left: '50%',
		cursor: 'pointer',
		transform: 'translate(-50%, -50%)',
	},
	newCourseTitleSection: {
		backgroundColor: '#FAE4D0',
		width: 'max-content',
		margin: 'auto',
		borderRadius: 8,
		cursor: 'pointer',
		border: '3px solid #FFFFFF',
		[theme.breakpoints.down(900)]: {
			width: 'auto',
			margin: 'auto',
		},
	},
	title: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		lineClamp: 2,
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
		textAlign: 'left',
	},
	arrowIcon: {
		borderRadius: '50%',
		backgroundColor: 'white',
		boxShadow: '0px 4px 4px rgb(0 0 0 / 3%)',
		cursor: 'pointer',
		padding: 4,
	},
	rocketImage: {
		width: 100,
		height: 75,
		marginTop: -20,
		[theme.breakpoints.down(600)]: {
			width: 90,
			height: 70,
		},
		[theme.breakpoints.down(440)]: {
			width: 75,
			height: 50,
			marginTop: 0,
		},
	},
	newCourseText: {
		fontSize: 20,
		fontWeight: 600,
		padding: 20,
		[theme.breakpoints.down(600)]: {
			fontSize: 14,
		},
		[theme.breakpoints.down(440)]: {
			fontSize: 12,
			padding: 10,
		},
		[theme.breakpoints.down(355)]: {
			fontSize: 10,
			padding: 8,
		},
	},
	activity: {
		backgroundColor: '#FFF',
		borderRadius: 6,
		boxShadow: '0px 4px 21px rgba(0, 0, 0, 0.08)',
		padding: '10px 20px',
	},
	activityHeader: {
		margin: '10px 0px 20px 0px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	activityTitle: {
		fontWeight: 600,
	},
	activityFeedback: {
		fontWeight: 600,
		color: '#00A0E3',
		cursor: 'pointer',
	},
	description: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		lineClamp: 2,
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
		margin: '20px 0px ',
	},
	drawerSpace: {
		width: 500,
		[theme.breakpoints.down(500)]: {
			width: '100%',
		},
	},
	drawerTitle: {
		fontWeight: 600,
		fontSize: 20,
	},
	header: {
		position: 'sticky',
		top: 0,
		width: '100%',
		backgroundColor: '#fff',
		zIndex: 20,
		padding: 20,
		borderBottom: '1px solid #C0C5D7',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		boxSizing: 'border-box',
	},
	closeIcon: {
		cursor: 'pointer',
	},
	mainContent: {},
	footer: {
		position: 'sticky',
		bottom: 0,
		width: '100%',
		backgroundColor: '#fff',
		zIndex: 20,
	},
	submitFeedback: {
		width: '100%',
		backgroundColor: '#EF7F1A',
		color: '#FFF',
		borderRadius: 0,
		padding: 15,
		fontSize: 15,
		fontWeight: 600,
		'&:hover': {
			backgroundColor: '#ff7900',
		},
	},
	dateBox: {
		width: '80px',
	},
	dateData: {
		padding: '5px 20px',
	},
	month: {
		backgroundColor: '#00A0E3',
		color: '#FFF',
		fontSize: 14,
		borderTopLeftRadius: 9,
		borderTopRightRadius: 9,
		padding: '2px 0px',
	},
	date: {
		border: '1px solid #00A0E3',
		borderBottomLeftRadius: 9,
		borderBottomRightRadius: 9,
		fontSize: 28,
		padding: '5px 10px',
	},
	inputs: {
		padding: 10,
		textAlign: 'left',
		width: '100%',
	},
	status: {
		color: '#9497A0',
		fontWeight: 600,
	},
	weakName: {
		fontWeight: 600,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		marginLeft: 30,
	},
	observationSection: {
		border: '3px solid #F2F4F8',
		padding: 16,
	},
	observationText: {
		textAlign: 'left',
		color: '#9497A0',
		fontWeight: 600,
		paddingBottom: 10,
	},
}));

export default useStyles;
