export async function loadScript(src: string) {
	return new Promise((resolve) => {
		const script = document.createElement('script');
		script.src = src;
		script.onload = () => {
			resolve(true);
		};
		script.onerror = () => {
			resolve(false);
		};
		document.body.appendChild(script);
	});
}

interface Props {
	orderId: string;
	amount: string;
	currency: string;
	x_auth_token: string;
	role: string;
	userId: string;
	studentDetail: any;
	handleRozarpayResponse: (
		x_auth_token: string,
		role: string,
		userId: string,
		advancedCourseStatus: string,
		studentDetail: any
	) => void;
	advancedCourseStatus?: string;
	adavanceCoursePaymentFlag?: boolean;
}

export const displayRozarpay = async ({
	orderId,
	amount,
	currency,
	x_auth_token,
	role,
	userId,
	handleRozarpayResponse,
	studentDetail,
	advancedCourseStatus,
	adavanceCoursePaymentFlag = false,
}: Props) => {
	const res = await loadScript(process.env.REACT_APP_ROZARPAY_URL || '');

	if (!res) {
		alert('Razorpay SDK failed to load. Are you online?');
		return;
	}

	const options = {
		key: process.env.REACT_APP_ROZARPAY_KEY, // Enter the Key ID generated from the Dashboard
		amount: amount,
		currency: currency,
		name: 'Anubhuti',
		description: adavanceCoursePaymentFlag
			? 'Advance Course Fees'
			: 'Foundation Course Fees',
		// image: { logo },
		order_id: orderId,
		handler: async function (response: any) {
			handleRozarpayResponse(
				x_auth_token,
				role,
				userId,
				advancedCourseStatus || 'false',
				studentDetail
			);
		},
		prefill: {
			name: 'Anubhuti',
			// email: 'anubhuti@gmail.com',
			// contact: '',
		},
		// notes: {
		// 	address: 'Anubhuti',
		// },
		theme: {
			color: '#EF7F1A',
		},
	};

	const paymentObject = new (window as any).Razorpay(options);
	paymentObject.open();
};

displayRozarpay.defaultProps = {
	advancedCourseStatus: 'false',
};
