import { FC, ReactElement } from 'react';
import { DateBoxType } from './type';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import useStyles from './style';

const DateBox: FC<DateBoxType> = ({
	monthName,
	date,
	weakDayName,
	feedback,
	status,
	id,
	handleChangeActivity,
}): ReactElement => {
	const classes = useStyles();
	return (
		<Box display='flex' alignItems='center' className={classes.dateData}>
			<Box className={classes.dateBox}>
				<Typography className={classes.month}>{monthName}</Typography>
				<Typography className={classes.date}> {date} </Typography>
				<Typography className={classes.weakName}> {weakDayName} </Typography>
			</Box>
			<Box className={classes.inputs}>
				<Box display='flex' alignItems='center'>
					<Typography className={classes.status}>Status</Typography>
					<FormControl className={classes.formControl}>
						<Select
							labelId='demo-simple-select-label'
							id='demo-simple-select'
							value={status}
							onChange={(e) => handleChangeActivity({ e, id })}
							name='status'
						>
							<MenuItem value={'DONE'}>DONE</MenuItem>
							<MenuItem value={'INPROGRESS'}>IN PROGRESS</MenuItem>
						</Select>
					</FormControl>
				</Box>
				<TextField
					fullWidth
					id='standard-required'
					defaultValue=''
					variant='outlined'
					multiline
					rows={2}
					value={feedback}
					name='feedback'
					onChange={(e) => handleChangeActivity({ e, id })}
					placeholder='Feedback of the day'
				/>
			</Box>
		</Box>
	);
};

export default DateBox;
