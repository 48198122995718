import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	notFound: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh',
	},
	notFoundText: {
		fontWeight: 600,
	},
}));

export default useStyles;
