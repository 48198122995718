import { FC, ReactElement, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SuspenseLoader from '../../components/SuspenseLoader';
import { Box, Typography } from '@material-ui/core';
import Layout from '../../components/Layout';
import Yoga from '../../assets/images/yoga_conntact.png';
import API from '../../api/Api';
import useStyles from './style';

const InstructorDetail: FC = (): ReactElement => {
	const classes = useStyles();
	const userID = localStorage.getItem('userId');
	const [instructorDetail, setInstructorDetails] = useState<any>({});
	const [loader, setLoader] = useState<boolean>(true);
	useEffect(() => {
		const getInstructorDetails = async () => {
			setLoader(true);
			try {
				const instructorDetails = await API.get(
					`student/${userID}/getInstructorDetails`
				);

				setInstructorDetails(instructorDetails);
			} catch (err: any) {
				toast.error(err.response.data.error);
			}
			setLoader(false);
		};
		getInstructorDetails();
	}, []);

	return (
		<Layout>
			{loader ? (
				<SuspenseLoader />
			) : (
				<>
					<Box m={2}>
						<Typography className={classes.heading}>
							Know your Instructor
						</Typography>
					</Box>
					{Object.keys(instructorDetail).length > 1 ? (
						<Box className={classes.container}>
							<Box className={classes.profileContainer}>
								<img
									className={classes.profile}
									src={instructorDetail?.profilePhotoUrl}
									alt='profile'
								/>
							</Box>
							<Box>
								<Box>
									<Typography className={classes.instructorName}>
										{instructorDetail?.instructor_name}
									</Typography>
									<Typography className={classes.instructorDescription}>
										{instructorDetail?.profileSummary}
									</Typography>
								</Box>
								<Box>
									<Typography className={classes.instructorProfileTitle}>
										Instructor Profile Gallary
									</Typography>
									<Box className={classes.imageContainer}>
										{instructorDetail &&
											instructorDetail?.instructorImageUrl.map(
												(img: string, index: any) => (
													<Box m={1} key={index + 1}>
														<img
															src={img}
															alt='profile'
															className={classes.instructorImages}
														/>
													</Box>
												)
											)}
									</Box>
								</Box>
								<Box>
									<Typography className={classes.instructorProfileTitle}>
										Instructor Videos
									</Typography>
									<Box className={classes.imageContainer}>
										{instructorDetail &&
											instructorDetail?.instructorVideoUrl.map(
												(videoUrl: string, index: any) => (
													<Box key={index + 1} m={1}>
														<video
															controls
															controlsList='nodownload'
															className={classes.instructorImages}
														>
															<source src={videoUrl || ''} type='video/mp4' />
														</video>
													</Box>
												)
											)}
									</Box>
								</Box>
							</Box>
						</Box>
					) : (
						<Box className={classes.notAssign}>
							<Typography className={classes.notAssignText}>
								Instructor is not assign yet please try to contact to admin.
							</Typography>
						</Box>
					)}
				</>
			)}
		</Layout>
	);
};

export default InstructorDetail;
