import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountCircle from '@material-ui/icons/AccountCircle';

import useStyles from './style';
import Logo from '../../Logo';
import { disableInstrunctorDetailFlag } from '../../../store/reducers/instaructorDetail';

const HomeHeader = () => {
	const classes = useStyles();
	let navigate = useNavigate();
	const dispatch = useDispatch();

	

	const instructorDetailFlag = useSelector(
		(state: any) => state.instructorDetail.instructorDetailFlag
	);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleProfileMenuOpen = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = (event: any) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const handleLogout = () => {
		dispatch(disableInstrunctorDetailFlag());
		localStorage.clear();
		navigate('/');
	};

	const menuId = 'primary-search-account-menu';
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<MenuItem onClick={() => navigate('/payment-history')}>
				<NavLink to='/payment-history' style={{ textDecoration: 'none' }}>
					{({ isActive }) => (
						<Typography
							variant='body1'
							noWrap
							className={`${classes.headerItem} ${
								isActive ? classes.active : ''
							}`}
						>
							Payment history
						</Typography>
					)}
				</NavLink>
			</MenuItem>

			<MenuItem onClick={() => navigate('/contact-us')}>
				<NavLink to='/contact-us' style={{ textDecoration: 'none' }}>
					{({ isActive }) => (
						<Typography
							variant='body1'
							noWrap
							className={`${classes.headerItem} ${
								isActive ? classes.active : ''
							}`}
						>
							Contact Us
						</Typography>
					)}
				</NavLink>
			</MenuItem>

			<MenuItem onClick={handleLogout}>Logout</MenuItem>
		</Menu>
	);

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem onClick={() => navigate('/privacy-policy')}>
				<NavLink to='/privacy-policy' style={{ textDecoration: 'none' }}>
					{({ isActive }) => (
						<Typography
							variant='body1'
							noWrap
							className={`${classes.headerItem} ${
								isActive ? classes.active : ''
							}`}
						>
							Privacy Policy
						</Typography>
					)}
				</NavLink>
			</MenuItem>
			<MenuItem onClick={() => navigate('/term-conditions')}>
				<NavLink to='/term-conditions' style={{ textDecoration: 'none' }}>
					{({ isActive }) => (
						<Typography
							variant='body1'
							noWrap
							className={`${classes.headerItem} ${
								isActive ? classes.active : ''
							}`}
						>
              Terms Conditions
						</Typography>
					)}
				</NavLink>
			</MenuItem>
	
			<MenuItem onClick={() => navigate('/refund')}>
				<NavLink to='/refund' style={{ textDecoration: 'none' }}>
					{({ isActive }) => (
						<Typography
							variant='body1'
							noWrap
							className={`${classes.headerItem} ${
								isActive ? classes.active : ''
							}`}
						>
							Refund
						</Typography>
					)}
				</NavLink>
			</MenuItem>

      <MenuItem onClick={() => navigate('/pricing')}>
				<NavLink to='/pricing' style={{ textDecoration: 'none' }}>
					{({ isActive }) => (
						<Typography
							variant='body1'
							noWrap
							className={`${classes.headerItem} ${
								isActive ? classes.active : ''
							}`}
						>
              Pricing
						</Typography>
					)}
				</NavLink>
			</MenuItem>

			<MenuItem onClick={() => navigate('/contact-us')}>
				<NavLink to='/contact-us' style={{ textDecoration: 'none' }}>
					{({ isActive }) => (
						<Typography
							variant='body1'
							noWrap
							className={`${classes.headerItem} ${
								isActive ? classes.active : ''
							}`}
						>
							Contact Us
						</Typography>
					)}
				</NavLink>
			</MenuItem>
			<MenuItem onClick={handleLogout}>
				<Typography variant='body1' noWrap className={`${classes.headerItem} `}>
					Logout
				</Typography>
			</MenuItem>
		</Menu>
	);
	
	return (
		<div className={classes.grow}>
			<AppBar position='static' className={classes.homeAppBar}>
				<Toolbar className={classes.homeToolBar}  >
					<Logo />

					<div className={classes.grow} />
					<div className={classes.sectionDesktop}>
						<NavLink to='/privacy-policy' style={{ textDecoration: 'none' }}>
							{({ isActive }) => (
								<Typography
									variant='body1'
									noWrap
									className={`${classes.headerItem} ${
										isActive ? classes.active : ''
									}`}
								>
									Privacy Policy
								</Typography>
							)}
						</NavLink>
						<NavLink to='/term-conditions' style={{ textDecoration: 'none' }}>
							{({ isActive }) => (
								<Typography
									variant='body1'
									noWrap
									className={`${classes.headerItem} ${
										isActive ? classes.active : ''
									}`}
								>
									Terms Conditions
								</Typography>
							)}
						</NavLink>
            <NavLink to='/refund' style={{ textDecoration: 'none' }}>
							{({ isActive }) => (
								<Typography
									variant='body1'
									noWrap
									className={`${classes.headerItem} ${
										isActive ? classes.active : ''
									}`}
								>
									Refund
								</Typography>
							)}
						</NavLink>
            <NavLink to='/pricing' style={{ textDecoration: 'none' }}>
							{({ isActive }) => (
								<Typography
									variant='body1'
									noWrap
									className={`${classes.headerItem} ${
										isActive ? classes.active : ''
									}`}
								>
									Pricing
								</Typography>
							)}
						</NavLink>
            <NavLink to='/contact-us' style={{ textDecoration: 'none' }}>
							{({ isActive }) => (
								<Typography
									variant='body1'
									noWrap
									className={`${classes.headerItem} ${
										isActive ? classes.active : ''
									}`}
								>
									Contact us
								</Typography>
							)}
						</NavLink>
					</div>
					<div className={classes.sectionMobile}>
						
						<IconButton
							aria-label='show more'
							aria-controls={mobileMenuId}
							aria-haspopup='true'
							onClick={handleMobileMenuOpen}
							color='inherit'
						>

							<ExpandMoreIcon style={{ color: '#000' }} />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
			{renderMenu}
		</div>
	);
};

export default HomeHeader;
