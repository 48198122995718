import React from 'react';
import { Navigate } from 'react-router-dom';

type Props = {
	children: React.ReactNode;
};

const PrivateRoute = ({ children }: Props) => {
	const auth = localStorage.getItem('userId');

	if (!auth) {
		return <Navigate to='/' />;
	}
	return <> {children} </>;
};

export default PrivateRoute;
