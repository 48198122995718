import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
	},
	active: {
		fontWeight: 'bold',
		textDecoration: 'none',
	},
	headerItem: {
		marginRight: 20,
		cursor: 'pointer',
		color: '#000',
	},
	appBar: {
		backgroundColor: '#FFF',
		boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.07)',
		border: '1px solid #C0C5D7',
	},
	homeAppBar:{
		backgroundColor: '#f2f2f2',
		boxShadow: '0px 4px 18px #f2f2f2',
	},
	homeToolBar:{
		padding:0
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			alignItems: 'center',
		},
	},
	welcomeMsg: {
		color: '#00A0E3',
		margin: '0px 10px',
		fontWeight: 600,
		fontSize: 15,
		[theme.breakpoints.down(960)]: {
			fontSize: 10,
			margin: 'auto 10px',
		},
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
}));

export default useStyles;
