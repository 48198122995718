import { FC, ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTable } from 'react-table';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';

type ChildProps = {
	data: Array<any>;
	columns: Array<any>;
};

const defaultProps: ChildProps = {
	data: [],
	columns: [],
};

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
});

const TableMetrix: FC<ChildProps> = ({ data, columns }): ReactElement => {
	const { getTableProps, headerGroups, rows, prepareRow } = useTable({
		columns,
		data,
	});
	const classes = useStyles();
	return (
		<TableContainer>
			<Table
				className={classes.table}
				aria-label='simple table'
				{...getTableProps()}
			>
				<TableHead>
					{headerGroups.map((headerGroup) => (
						<TableRow {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column: any) => (
								<TableCell
									{...column.getHeaderProps({
										style: { ...column?.headerStyle },
									})}
								>
									{column.render('Header')}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableHead>
				<TableBody>
					{rows.map((row, i) => {
						prepareRow(row);
						return (
							<TableRow {...row.getRowProps()}>
								{row.cells.map((cell: any) => {
									return (
										<TableCell
											{...cell.getCellProps({
												style: { ...cell?.column?.dataStyle },
											})}
										>
											{cell.render('Cell')}
										</TableCell>
									);
								})}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

TableMetrix.defaultProps = defaultProps as Partial<ChildProps>;

export default TableMetrix;
