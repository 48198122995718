import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	yogaImg: {
		height: 500,
	},
	mainContainer: {
		display: 'flex',
		alignItems: 'start',
		padding: 24,
		[theme.breakpoints.down(768)]: {
			flexDirection: 'column',
		},
	},
	imageContainer: {
		width: '33%',
		textAlign: 'center',
		[theme.breakpoints.down(960)]: {
			display: 'none',
		},
	},
	contactFormContainer: {
		width: '33%',
		[theme.breakpoints.down(960)]: {
			width: '70%',
		},
		[theme.breakpoints.down(768)]: {
			width: '100%',
		},
	},
	addressContainer: {
		width: '33%',
		marginTop: 90,
		marginLeft: 32,
		[theme.breakpoints.down(960)]: {
			width: '30%',
		},
		[theme.breakpoints.down(768)]: {
			width: '100%',
			marginTop: 50,
			marginLeft: 0,
		},
	},
	title: {
		fontSize: 30,
		fontWeight: 600,
		marginBottom: 30,
	},
	submitContactus: {
		width: '100%',
		backgroundColor: '#EF7F1A',
		color: '#FFF',
		padding: 12,
		fontWeight: 'bold',
		textTransform: 'capitalize',
		'&:hover': {
			backgroundColor: '#EF7F1A',
		},
	},
	iconColor: {
		color: '#525356',
	},
	links: {
		fontSize: 14,
		color: '#525356',
		marginLeft: 10,
	},
}));

export default useStyles;
