import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		height: '100%',
		minHeight: '100vh',
		[theme.breakpoints.down(1180)]: {
			borderTop: '15px solid #28B8F4',
		},
	},
	registerContainer: {
		width: '40%',
		padding: '24px 30px',
		[theme.breakpoints.down(1180)]: {
			width: '100%',
		},
	},
	welcomText: {
		color: '#525356',
		fontWeight: 600,
		fontSize: 24,
		marginBottom: 16,
		[theme.breakpoints.down(600)]: {
			fontSize: 18,
			textAlign: 'center',
		},
	},
	backText: {
		display: 'flex',
		columnGap: 8,
		color: '#EF7F1A',
		cursor: 'pointer',
	},
	form: {
		border: '1px solid #C0C5D7',
		boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.06)',
		borderRadius: 11,
		padding: '42px 24px',
		[theme.breakpoints.down(600)]: {
			padding: 0,
			border: 'none',
			boxShadow: 'none',
		},
	},
	fileIcon: {
		cursor: 'pointer',
		transform: 'rotate(45deg)',
	},
	enterOTP: {
		color: '#9497A0',
		fontWeight: 600,
	},
	resendOTP: {
		color: '#00A0E3',
		fontWeight: 600,
		cursor: 'pointer',
	},
	registerButton: {
		background: '#EF7F1A',
		borderRadius: 7,
		width: '100%',
		marginTop: 16,
		color: '#FFF',
		padding: '15px',
		fontWeight: 600,
		fontSize: 16,
		'&:hover': {
			background: '#EF7F1A',
		},
	},
	loginRedirect: {
		color: '#EF7F1A',
		fontWeight: 600,
		textAlign: 'end',
		cursor: 'pointer',
		marginTop: 8,
	},
	animationContainer: {
		width: '60%',
		[theme.breakpoints.down(1180)]: {
			display: 'none',
		},
	},
}));

export default useStyles;
