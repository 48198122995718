import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	logo: {
		width: 100,
		height: 'auto',
		cursor: 'pointer',
	},
}));

export default useStyles;
