import { FC, ReactElement, useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { VideoContainerType } from './type';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PerchasedActivity from './PerchasedActivity';
import Rocket from '../../assets/images/rocket.png';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Avatar, Box, Typography, Button } from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Layout from '../../components/Layout';
import SuspenseLoader from '../../components/SuspenseLoader';
import { displayRozarpay } from '../../utills/rozarpay';
import API from '../../api/Api';
import useStyles from './style';
import { enableInstrunctorDetailFlag } from '../../store/reducers/instaructorDetail';

const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
	},
	tablet: {
		breakpoint: { max: 1024, min: 600 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 600, min: 0 },
		items: 1,
	},
};

const VideoContainer: FC<VideoContainerType> = ({
	id,
	title,
	poster,
}): ReactElement => {
	const classes = useStyles();

	const [videoUrl, setVideoUrl] = useState('');
	const [flag, setFlag] = useState(false);

	const userID = localStorage.getItem('userId');

	const getVideo = async (videoId: string) => {
		try {
			const getVideoAccess: any = await API.get(
				`course/getSignedUrl/${userID}/${videoId}`
			);
			await API.post(`course/contentHistory/${userID}/${videoId}`);
			setVideoUrl(getVideoAccess?.url);
		} catch (err: any) {
			toast.error(err.response.data.error);
		}
	};
	useEffect(() => {
		if (videoUrl !== '') {
			setFlag(true);
		}
	}, [videoUrl]);

	return (
		<Box m={2}>
			{flag ? (
				<video
					controls
					className={classes.video}
					controlsList='nodownload'
					poster={poster}
					autoPlay
				>
					<source src={videoUrl} />
				</video>
			) : (
				<Box
					className={classes.video}
					style={{
						background: `url(${poster})`,
						backgroundSize: '100% 100%',
						position: 'relative',
					}}
				>
					<PlayCircleFilledWhiteIcon
						onClick={() => getVideo(id)}
						className={classes.playIcon}
					/>
				</Box>
			)}
			<Typography className={classes.title}>{title}</Typography>
		</Box>
	);
};

const Dashboard = () => {
	const classes = useStyles();
	const carouselRef = useRef<any>(null);
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const [basicCourse, setBasicCourse] = useState<[] | null>(null);
	const [adavanceCourse, setAdavanceCourse] = useState<[]>([]);

	const advancedCourseStatus = localStorage.getItem('advancedCourseStatus');
	const [showAdavanceCourse, setShowAdavanceCourse] = useState<string | null>(
		advancedCourseStatus
	);

	const userID = localStorage.getItem('userId');
	let x_auth_token = localStorage.getItem('token');
	let role = localStorage.getItem('role');
	let userId = localStorage.getItem('userId');
	let studentDetail = localStorage.getItem('studentDetail');

	const getAdavanceCourse = async () => {
		if (userID) {
			try {
				const basicCourseFetch: any = await API.get(
					`course/getAdvancedCourseDetails/${userID}`
				);
				setAdavanceCourse(basicCourseFetch?.courseDetails?.activitys);
			} catch (err: any) {
				toast.error(err.response.data.error);
			}
		} else {
			localStorage.clear();
			navigate('/');
		}
	};

	useEffect(() => {
		const getBasicCourse = async () => {
			if (userID) {
				try {
					const basicCourseFetch: any = await API.get(
						`course/getBasicCourseDetails/${userID}`
					);

					const allBasicCourse = basicCourseFetch?.courseDetails?.contents;
					setBasicCourse(allBasicCourse);
				} catch (err: any) {
					toast.error(err.response.data.error);
				}
			} else {
				localStorage.clear();
				navigate('/');
			}
		};

		getBasicCourse();
	}, []);

	useEffect(() => {
		if (showAdavanceCourse === 'true') {
			getAdavanceCourse();
			dispatch(enableInstrunctorDetailFlag());
		}
	}, [showAdavanceCourse]);

	const handleRozarpayResponse = () => {
		localStorage.setItem('advancedCourseStatus', 'true');
		dispatch(enableInstrunctorDetailFlag());
		setShowAdavanceCourse('true');
	};

	const adavanceCoursePayment = async () => {
		try {
			const getOrderForAdavanceCourse: any = await API.post(
				`course/createOrderForAdvancedCourse/${userID}`
			);
			let adavanceCoursePaymentFlag = true;
			const { id, amount, currency } = getOrderForAdavanceCourse.order;
			if (x_auth_token && role && userId) {
				displayRozarpay({
					orderId: id,
					amount,
					currency,
					x_auth_token,
					role,
					userId,
					studentDetail,
					adavanceCoursePaymentFlag,
					handleRozarpayResponse,
				});
			} else {
				localStorage.clear();
				navigate('/');
			}
		} catch (err: any) {
			toast.error(err.response.data.error);
		}
	};

	return (
		<Layout>
			{basicCourse === null ? (
				<SuspenseLoader />
			) : (
				<>
					<Box m={2}>
						<Box
							m={2}
							display='flex'
							justifyContent='space-between'
							alignItems='center'
						>
							<Box>
								<Typography className={classes.courseText}>
									OEWC Basic Course Videos
								</Typography>
							</Box>
							<Box display='flex' alignItems='center'>
								<Box mr={3}>
									<ChevronLeftIcon
										className={classes.arrowIcon}
										onClick={() => carouselRef.current.previous()}
									/>
								</Box>
								<Box mr={3}>
									<ChevronRightIcon
										className={classes.arrowIcon}
										onClick={() => carouselRef.current.next()}
									/>
								</Box>
							</Box>
						</Box>
						<Carousel ref={carouselRef} arrows={false} responsive={responsive}>
							{basicCourse.map(({ _id, content_name, thumbnailUrl }: any) => (
								<Box key={_id}>
									<VideoContainer
										id={_id}
										title={content_name}
										poster={thumbnailUrl}
									/>
								</Box>
							))}
						</Carousel>
					</Box>
					{showAdavanceCourse !== 'true' && (
						<Box m={3}>
							<Box
								display='flex'
								alignItems='center'
								justifyContent='center'
								className={classes.newCourseTitleSection}
								// onClick={() => adavanceCoursePayment()}
							>
								<img
									className={classes.rocketImage}
									src={Rocket}
									alt='rocket'
								/>
								<Typography className={classes.newCourseText}>
									Advanced Course coming soon...
								</Typography>
							</Box>
						</Box>
					)}
					{showAdavanceCourse === 'true' && (
						<PerchasedActivity adavanceCourse={adavanceCourse} />
					)}
				</>
			)}
		</Layout>
	);
};
export default Dashboard;
