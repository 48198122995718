import { FC, ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { useForm } from 'react-hook-form';
import {
	Box,
	Button,
	Grid,
	InputAdornment,
	Typography,
} from '@material-ui/core';
import Logo from '../../components/Logo';
import TextField from '../../components/Form/TextField';
import Animation from '../Home/Animation';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { displayRozarpay } from '../../utills/rozarpay';
import API from '../../api/Api';
import { loginSchema } from '../../schema/validationSchema';
import useStyles from './style';

const Login: FC = (): ReactElement => {
	const classes = useStyles();
	const [verifyOtp, setVerifyOtp] = useState<boolean>(false);
	const [otp, setOtp] = useState('');
	const navigate = useNavigate();

	const {
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm({ resolver: yupResolver(loginSchema) });

	const handleResendAPi = async () => {
		try {
			const resendOTP: any = await API.get(
				`auth/generate?phone=${watch('phone')}/otp`
			);
			if (resendOTP.success || resendOTP.role) {
				toast.success('OTP sent successfully');
			}
		} catch (err: any) {
			toast.error(err.response.data.error);
		}
	};

	const setLocalStorageAuthData = (
		x_auth_token: string,
		role: string,
		userId: string,
		advancedCourseStatus: string,
		studentDetail: any
	) => {
		localStorage.setItem('token', x_auth_token);
		localStorage.setItem('role', role);
		localStorage.setItem('userId', userId);
		localStorage.setItem('advancedCourseStatus', advancedCourseStatus);
		localStorage.setItem('studentDetail', JSON.stringify(studentDetail));
	};

	const handleRozarpayResponse = (
		x_auth_token: string,
		role: string,
		userId: string,
		advancedCourseStatus: string,
		studentDetail: any
	) => {
		setLocalStorageAuthData(
			x_auth_token,
			role,
			userId,
			advancedCourseStatus,
			studentDetail
		);
		navigate('/dashboard');
	};

	const onSubmit = async (data: any) => {
		const { phone } = data;
		if (!verifyOtp) {
			// first time send otp to mobile
			try {
				const sendOTP: any = await API.get(`auth/generate?phone=${phone}`);
				if (sendOTP?.success) {
					toast.success('OTP sent successfully');
					setVerifyOtp(true);
				}
			} catch (err: any) {
				toast.error(err.response.data.error);
			}
		} else {
			if (otp && otp.length === 4) {
				// Register student api
				try {
					const loginUser: any = await API.post('auth', {
						user_phone: phone,
						otp,
					});

					const {
						x_auth_token,
						role,
						userId,
						advancedCourseStatus,
						studentDetail,
					} = loginUser;
					if (loginUser?.order) {
						const { id, amount, currency, status } = loginUser.order;
						if (status === 'paid') {
							setLocalStorageAuthData(
								x_auth_token,
								role,
								userId,
								advancedCourseStatus,
								studentDetail
							);
							navigate('/dashboard');
						} else {
							displayRozarpay({
								orderId: id,
								amount,
								currency,
								x_auth_token,
								role,
								userId,
								studentDetail,
								handleRozarpayResponse,
								advancedCourseStatus,
							});
						}
					} else {
						setLocalStorageAuthData(
							x_auth_token,
							role,
							userId,
							advancedCourseStatus,
							studentDetail
						);
						navigate('/dashboard');
					}
				} catch (err: any) {
					toast.error(err.response.data.error);
				}
			} else {
				toast.error('Please enter OTP');
			}
		}
	};
	const onRedirectToRegister = () => {
		navigate('/register');
	};
	return (
		<Box className={classes.container}>
			<Box className={classes.loginContainer}>
				<Box pb={4}>
					<Logo auth={true} />
				</Box>
				<Box mt={7}>
					<Typography className={classes.welcomText}>
						Welcome Login With Anubhuti
					</Typography>
					<Box className={classes.form}>
						<form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
							<Grid item xs={12}>
								<TextField
									name='phone'
									type='number'
									defaultValue=''
									label='Phone number *'
									rules={{ required: 'Please enter a phone number' }}
									variant='outlined'
									control={control}
									error={!!errors.phone}
									helperText={errors.phone ? errors?.phone?.message : ''}
									onWheel={(event: any) => event.currentTarget.blur()}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<Typography> +91</Typography>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							{verifyOtp && (
								<Grid item xs={12}>
									<Box
										display='flex'
										alignItems='center'
										justifyContent='space-between'
										py={1}
									>
										<Typography variant='body2' className={classes.enterOTP}>
											Enter OTP
										</Typography>
										<Typography
											variant='body2'
											onClick={handleResendAPi}
											className={classes.resendOTP}
										>
											Resend OTP
										</Typography>
									</Box>
									<OtpInput
										value={otp}
										onChange={(otp: any) => setOtp(otp)}
										isInputNum={true}
										placeholder='Enter Otp'
										separator={<span>-</span>}
										containerStyle={{
											width: '100%',
										}}
										inputStyle={{
											width: '100%',
											padding: '15px',
											fontSize: '1rem',
										}}
										hasErrored={true}
									/>
								</Grid>
							)}
							<Typography
								onClick={onRedirectToRegister}
								className={classes.registerRedirect}
							>
								Register With Anubhuti
							</Typography>
							<Button
								type='submit'
								variant='contained'
								className={classes.loginButton}
							>
								Continue
							</Button>
						</form>
					</Box>
				</Box>
			</Box>
			<Box className={classes.animationContainer}>
				<Animation />
			</Box>
		</Box>
	);
};

export default Login;
