import HomeHeader from '../../components/Layout/Header/HomeHeader'
import { Box, Button, Typography } from '@material-ui/core';
import useStyles from './styles';
const Refund = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
    <Box className={classes.videoContainer}>
      <Box pb={4}>
        <HomeHeader />	
      </Box>
      <Box m={1} pb={2}>
        
        <Box className={classes.mainTitle} > REFUND POLICY </Box>
        <Box className={classes.title} >  No Refunds  </Box> 
        <Box component="p" className={classes.text}> We do not provide any refund. The courses provided are accessible as per the guidelines provided in course description. </Box>
        <Box component="p" className={classes.text}>Please get in touch with  us at <a target="_blank" rel="noreferrer" href="mailto:anubhutiminds2022@gmail.com"> <strong> anubhutiminds2022@gmail.com </strong> </a> for any queries</Box>
      </Box>
    </Box>
  </Box>
  )
}

export default Refund