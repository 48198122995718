import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	heading: {
		fontWeight: 600,
		fontSize: 26,
		display: 'none',
		[theme.breakpoints.down(600)]: {
			display: 'block',
		},
	},
	container: {
		display: 'flex',
		alignItems: 'flex-start',
		columnGap: 20,
		margin: 10,
		[theme.breakpoints.down(600)]: {
			flexDirection: 'column',
		},
	},
	profileContainer: {
		[theme.breakpoints.down(600)]: {
			margin: 'auto',
		},
	},
	profile: {
		width: 300,
		height: 300,
		borderRadius: 9,
	},
	instructorName: {
		fontSize: 28,
		fontWeight: 600,
		margin: '20px 0px',
	},
	instructorProfileTitle: {
		fontSize: 24,
		fontWeight: 600,
		margin: '20px 0px',
	},
	instructorDescription: {
		color: '#525356',
		wordBreak: 'break-word',
	},
	instructorImages: {
		width: 200,
		height: 150,
		borderRadius: 9,
	},
	imageContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		columnGap: 10,
		[theme.breakpoints.down(600)]: {
			justifyContent: 'space-evenly',
		},
		// justifyContent: 'space-evenly',
	},
	notAssign: {
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	notAssignText: {
		fontWeight: 600,
		fontSize: 20,
	},
}));
export default useStyles;
