import { ReactElement, FC, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';
import {
	Box,
	Typography,
	Grid,
	InputAdornment,
	Button,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import RoomIcon from '@material-ui/icons/Room';
import TextField from '../../components/Form/TextField';
import Yoga from '../../assets/images/yoga_conntact.png';
import Layout from '../../components/Layout';
import { contactUsSchema } from '../../schema/validationSchema';
import { toast } from 'react-toastify';

import API from '../../api/Api';

import useStyles from './style';

const ContactUs: FC = (): ReactElement => {
	const classes = useStyles();
	const userID = localStorage.getItem('userId');

	const {
		control,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm({ resolver: yupResolver(contactUsSchema) });

	const onSubmit = async (data: any) => {
		const { name, phone, msg } = data;
		try {
			const addConstactUs: any = await API.post(`student/contact/${userID}`, {
				user_id: userID,
				full_name: name,
				phone_number: phone,
				message: msg,
			});
			toast.success(addConstactUs.message);
		} catch (err: any) {
			toast.error(err.response.data.error);
		}
	};

	useEffect(() => {
		const userData = JSON.parse(localStorage.getItem('studentDetail') || '');
		setValue('name', userData?.student_name || '');
		setValue('phone', userData?.user_phone || '');
	}, []);

	return (
		<Layout>
			<Box className={classes.mainContainer}>
				<Box className={classes.imageContainer}>
					<img
						className={classes.yogaImg}
						alt='yoga'
						src={Yoga}
						loading='lazy'
					/>
				</Box>
				<Box className={classes.contactFormContainer}>
					<Typography className={classes.title}>Contact us</Typography>
					<Box>
						<form onSubmit={handleSubmit(onSubmit)} autoComplete='on'>
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<TextField
										name='name'
										type='text'
										defaultValue=''
										label='Full name *'
										rules={{ required: 'Please enter a full name' }}
										variant='outlined'
										control={control}
										error={!!errors.name}
										helperText={errors.name ? errors?.name?.message : ''}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										name='phone'
										type='number'
										defaultValue=''
										label='Phone number *'
										rules={{ required: 'Please enter a phone number' }}
										variant='outlined'
										control={control}
										error={!!errors.phone}
										helperText={errors.phone ? errors?.phone?.message : ''}
										onWheel={(event: any) => event.currentTarget.blur()}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<Typography> +91</Typography>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										name='msg'
										type='text'
										defaultValue=''
										label='Enter Message *'
										rules={{ required: 'Please enter a message' }}
										variant='outlined'
										control={control}
										error={!!errors.name}
										helperText={errors.name ? errors?.name?.message : ''}
										multiline
										rows={6}
									/>
								</Grid>
								<Button
									type='submit'
									variant='contained'
									className={classes.submitContactus}
								>
									Submit message
								</Button>
							</Grid>
						</form>
					</Box>
				</Box>
				<Box className={classes.addressContainer}>
					<Box display='flex' alignItems='center'>
						<OpenInNewIcon className={classes.iconColor} />
						<Typography className={classes.links}>
							https://anubhutiminds.com/
						</Typography>
					</Box>
					<Box display='flex' alignItems='center' mt={2}>
						<MailOutlineIcon className={classes.iconColor} />
						<Typography className={classes.links}>
							progress.anubhuti@gmail.com
						</Typography>
					</Box>
					<Box display='flex' alignItems='start' mt={2}>
						<RoomIcon className={classes.iconColor} />
						<Typography className={classes.links}>
							Vasundhara Bungalow,
							<br /> Plot No. 30,
							<br /> Prosperity Housing Society,
							<br /> Near Pratidnya Mangal Karyalaya,
							<br /> Karvenagar, Pune 411052
						</Typography>
					</Box>
				</Box>
			</Box>
		</Layout>
	);
};

export default ContactUs;
