import HomeHeader from '../../components/Layout/Header/HomeHeader'
import { Box, Button, Typography,Card,CardContent  } from '@material-ui/core';
import useStyles from './styles';

const Contactus = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
			<Box className={classes.videoContainer}>
        <Box pb={4}>
					<HomeHeader />	
				</Box>
        <Box m={1} pb={2}>
        <Box className={classes.mainTitle} > CONTACT US </Box>
        <Box className={classes.cardContainer} >
          <Card className={classes.card} >
            <CardContent>
              <Typography>
                  <strong>
                    <pre>
                    Anubhuti, <br />
                    Sameer Khadye, <br />
                    249, Narayan Peth, <br />
                    Behind Pankaj Music Centre, <br />
                    Garud Ganpati Lane, <br />
                    Near Apurvai hotel, <br />
                    Off Laxmi Road,<br />
                    Pune 411030.<br />
                    </pre>
                  </strong>
              </Typography>
              <Typography>
                  <strong>
                    <pre>
                    Email : anubhutiminds2022@gmail.com <br />
                    Phone : 095270 86670 / 098500 89060
                    </pre>
                  </strong>
              </Typography>

            </CardContent>        
          </Card>
        </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Contactus