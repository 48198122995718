import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	stepper: {
		backgroundColor: '#F2F2F2',
	},
	circle: {
		zIndex: 2,
		width: 8,
		height: 8,
		borderRadius: '50%',
		backgroundColor: '#000',
	},
	video: {
		height: '150px',
		width: '258px',
		[theme.breakpoints.down(600)]: {
			width: '45vw',
		},
	},
	videoInfo: {
		margin: 15,
		[theme.breakpoints.down(600)]: {
			marginLeft: 10,
			marginRight: 10,
		},
	},
	title: {
		fontWeight: 600,
		fontSize: 20,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		lineClamp: 2,
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
		[theme.breakpoints.down(600)]: {
			fontSize: 18,
		},
		[theme.breakpoints.down(400)]: {
			fontSize: 14,
		},
	},
	date: {
		color: '#525356',
		fontSize: 16,
		fontWeight: 600,
		[theme.breakpoints.down(600)]: {
			fontSize: 14,
		},
		[theme.breakpoints.down(400)]: {
			fontSize: 12,
		},
	},
	playIcons: {
		fontSize: 20,
	},
	dotIcons: {
		fontSize: 10,
	},
	buttons: {
		display: 'flex',
		alignItems: 'center',
		columnGap: 8,
		[theme.breakpoints.down(600)]: {},
	},
	playButtons: {
		display: 'flex',
		alignItems: 'center',
		color: '#00A0E3',
		marginTop: 30,
		[theme.breakpoints.down(400)]: {
			marginTop: 5,
		},
		'& span': {
			fontWeight: 600,
			marginLeft: 7,
			marginTop: 1,
			fontSize: 15,
			[theme.breakpoints.down(600)]: {
				fontSize: 12,
			},
		},
		'&:hover': {
			cursor: 'pointer',
		},
	},
	reportButtons: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 30,
		[theme.breakpoints.down(400)]: {
			marginTop: 5,
		},
		'& span': {
			fontWeight: 600,
			marginLeft: 7,
			color: '#EF7F1A',
			fontSize: 15,
			[theme.breakpoints.down(600)]: {
				fontSize: 12,
			},
		},
		'&:hover': {
			cursor: 'pointer',
		},
	},
	lastElement: {
		borderLeft: '1px solid #bdbdbd !important',
	},
	drawerSpace: {
		width: '50%',
	},
	mobileView: {
		display: 'none',
		[theme.breakpoints.down(600)]: {
			display: 'block',
		},
	},
	playIcon: {
		position: 'absolute',
		fontSize: 40,
		top: '50%',
		left: '50%',
		cursor: 'pointer',
		transform: 'translate(-50%, -50%)',
	},
	noActivity: {
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

export default useStyles;
