import { FC, ReactElement, useMemo, useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SuspenseLoader from '../../components/SuspenseLoader';
import Box from '@material-ui/core/Box';
import { columns } from './columns';
import Layout from '../../components/Layout';
import Table from '../../components/Table';
import useStyles from './style';
import API from '../../api/Api';
import { Paper, Typography } from '@material-ui/core';
import classNames from 'classnames';
const PaymentHistory: FC = (): ReactElement => {
	const userID = localStorage.getItem('userId');
	const classes = useStyles();
	let navigate = useNavigate();
	const [data, setData] = useState<[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const column = useMemo(() => columns, []);
	useEffect(() => {
		const getPaymentHistory = async () => {
			setLoading(true);
			try {
				const paymentHistory = await API.get(`course/paymentHistory/${userID}`);
				//
				const paymentHistoryData: any =
					(Array.isArray(paymentHistory) &&
						paymentHistory.map((paymentData) => ({
							date: paymentData?.items[0]?.created_at,
							description: paymentData?.items[0]?.description,
							paymentMethod: paymentData?.items[0]?.method,
							amount: parseInt(paymentData?.items[0]?.amount),
						}))) ||
					[];
				setData(paymentHistoryData);
			} catch (err: any) {
				toast.error(err.response.data.error);
			}
			setLoading(false);
		};
		getPaymentHistory();
	}, []);
	const handleToRedirectContactUs = () => {
		navigate('/contact-us');
	};

	return (
		<Layout>
			{loading ? (
				<SuspenseLoader />
			) : (
				<>
					<Box className={classes.desktopView}>
						<Table columns={column(handleToRedirectContactUs)} data={data} />
					</Box>

					<Box className={classes.mobileView}>
						<Typography className={classes.title}>Payment History</Typography>
						{data.map((paymentDetail: any) => (
							<Box p={1} className={classes.card}>
								<Box className={classNames(classes.cardBadge, classes.odd)}>
									<Typography className={classes.cardTitle}> DATE</Typography>
									<Typography>
										{moment.unix(paymentDetail.date).format('MMM DD, YYYY')}
									</Typography>
								</Box>
								<Box className={classNames(classes.cardBadge, classes.even)}>
									<Typography className={classes.cardTitle}>
										Description
									</Typography>
									<Typography className={classes.longText}>
										{paymentDetail.description}
									</Typography>
								</Box>
								{/* <Box className={classNames(classes.cardBadge, classes.odd)}>
										<Typography className={classes.cardTitle}>Validity</Typography>
										<Typography>No expiry date</Typography>
									</Box> */}
								<Box className={classNames(classes.cardBadge, classes.odd)}>
									<Typography className={classes.cardTitle}>
										Payment Method
									</Typography>
									<Typography>{paymentDetail.paymentMethod}</Typography>
								</Box>
								<Box className={classNames(classes.cardBadge, classes.even)}>
									<Typography className={classes.cardTitle}>Amount</Typography>
									<Typography>{`INR ${parseFloat(
										(paymentDetail.amount / 100).toString()
									).toFixed(2)}`}</Typography>
								</Box>
								<Box className={classNames(classes.cardBadge, classes.odd)}>
									<Typography className={classes.cardTitle}></Typography>
									<Typography
										className={classes.report}
										onClick={handleToRedirectContactUs}
									>
										Report a Problem
									</Typography>
								</Box>
							</Box>
						))}
					</Box>
				</>
			)}
		</Layout>
	);
};
export default PaymentHistory;
