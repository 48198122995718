import { useRef, useState, useEffect } from 'react';
import fileDownload from 'js-file-download';
import { Box, Button, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import GetAppIcon from '@material-ui/icons/GetApp';
import Carousel from 'react-multi-carousel';
import { useNavigate } from 'react-router-dom';
import Logo from '../../components/Logo';

import freeCourse from '../../constants/anubhuti-free-course.json';
import useStyles from './styles';

import 'react-multi-carousel/lib/styles.css';
import HomeHeader from '../../components/Layout/Header/HomeHeader';

const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
	},
	tablet: {
		breakpoint: { max: 1024, min: 600 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 600, min: 0 },
		items: 1,
	},
};

const Home = () => {
	const classes = useStyles();

	const [freeVideos, setFreeVideos] = useState<any>([]);
	const [freePoster, setFreePoster] = useState<any>([]);
	const [freePDF, setFreePDF] = useState<any>([]);

	const carouselVideoRef = useRef<any>(null);
	const carouselImagesRef = useRef<any>(null);
	const carouselPDFRef = useRef<any>(null);
	let navigate = useNavigate();

	useEffect(() => {
		freeCourse?.freecourses.forEach((freeContent: any) => {
			switch (freeContent?.type) {
				case 'video':
					setFreeVideos((prewState: any) => [...prewState, freeContent]);
					break;
				case 'image':
					setFreePoster((prewState: any) => [...prewState, freeContent]);
					break;
				case 'pdf':
					setFreePDF((prewState: any) => [...prewState, freeContent]);
					break;
			}
		});
	}, []);

	// const handleImageDownload = (url: string) => {
	// 	var file = new Blob([url], { type: 'image/*' });
	// 	// element.href = URL.createObjectURL(file);
	// 	// element.download = 'poster.png';
	// 	// element.click();
	// 	fileDownload(file, 'poster.png');
	// };

	return (
		<Box className={classes.container}>
			<Box className={classes.videoContainer}>
				<Box pb={4}>
					<HomeHeader />	
				</Box>
				<Box m={1}>
					<Box
						my={2}
						display='flex'
						justifyContent='space-between'
						alignItems='center'
					>
						<Box>
							<Typography className={classes.courseText}>
								घरोघरी अनुभूती
							</Typography>
						</Box>
						<Box display='flex' alignItems='center'>
							<Box mr={3}>
								<ChevronLeftIcon
									className={classes.arrowIcon}
									onClick={() => carouselVideoRef.current.previous()}
								/>
							</Box>
							<Box mr={3}>
								<ChevronRightIcon
									className={classes.arrowIcon}
									onClick={() => carouselVideoRef.current.next()}
								/>
							</Box>
						</Box>
					</Box>
					<Carousel
						ref={carouselVideoRef}
						arrows={false}
						responsive={responsive}
					>
						{freeVideos.map((video: any, index: any) => (
							<Box mb={2} p={1} key={index}>
								<video
									controls
									className={classes.video}
									controlsList='nodownload'
									poster={video.thumbnail}
								>
									<source src={video.url} type='video/mp4' />
								</video>
							</Box>
						))}
					</Carousel>
				</Box>
				<Box m={1}>
					<Box
						my={2}
						display='flex'
						justifyContent='space-between'
						alignItems='center'
					>
						<Box>
							<Typography className={classes.courseText}>
								अनुभूती पोस्टर्स (Pledge & Healthy Habits) - Right Click And Save Image ! 
							</Typography>
						</Box>
						<Box display='flex' alignItems='center'>
							<Box mr={3}>
								<ChevronLeftIcon
									className={classes.arrowIcon}
									onClick={() => carouselImagesRef.current.previous()}
								/>
							</Box>
							<Box mr={3}>
								<ChevronRightIcon
									className={classes.arrowIcon}
									onClick={() => carouselImagesRef.current.next()}
								/>
							</Box>
						</Box>
					</Box>
					<Carousel
						ref={carouselImagesRef}
						arrows={false}
						responsive={responsive}
					>
						{freePoster.map((video: any, index: any) => (
							<Box mb={2} p={1} key={index}>
								<img
									className={classes.video}
									src={video.url}
									alt={video.name}
								/>
							</Box>
						))}
					</Carousel>
				</Box>
				<Box m={1}>
					<Box
						my={2}
						display='flex'
						justifyContent='space-between'
						alignItems='center'
					>
						<Box>
							<Typography className={classes.courseText}>
								मनाची आंघोळ WorkBook (एकाग्रता व मानसिक स्वास्थ्य सुधारण्याचे सोपे उपाय) - Download Free PDF !
							</Typography>
						</Box>
						<Box display='flex' alignItems='center'>
							<Box mr={3}>
								<ChevronLeftIcon
									className={classes.arrowIcon}
									onClick={() => carouselPDFRef.current.previous()}
								/>
							</Box>
							<Box mr={3}>
								<ChevronRightIcon
									className={classes.arrowIcon}
									onClick={() => carouselPDFRef.current.next()}
								/>
							</Box>
						</Box>
					</Box>
					<Carousel ref={carouselPDFRef} arrows={false} responsive={responsive}>
						{freePDF.map((pdf: any, index: any) => (
							<Box mb={2} p={1} key={index} className={classes.pdf}>
								<Typography
									className={classes.openPDFText}
									onClick={() => window.open(pdf.url, '_blank')}
								>
									View in large screen
								</Typography>
								<iframe
									className={classes.pdfIframe}
									src={pdf.url}
									title='pdf'
									width='100%'
								/>
							</Box>
						))}
					</Carousel>
				</Box>
			</Box>
			<Box className={classes.footerbuttons}>
				<Box className={classes.buttons}>
					<Box>
						<Button
							onClick={() => navigate('login')}
							className={classes.loginButton}
						>
							Already registered? Login here
						</Button>
					</Box>
					<Box>
						<Button
							onClick={() => navigate('register')}
							className={classes.registerButton}
						>
							Register to basic course
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Home;
