import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { LogoType } from './type';
import { Avatar, Box } from '@material-ui/core';
import LogoImage from '../../assets/images/logo.png'
import useStyles from './style';

const Logo: FC<LogoType> = ({ auth }): ReactElement => {
	const classes = useStyles();

	return (
		<Link to={auth ? '/' : '/dashboard'}>
			<Box>
				<Avatar
					variant='square'
					alt='logo'
					src={LogoImage}
					className={classes.logo}
				/>
			</Box>
		</Link>
	);
};

export default Logo;
